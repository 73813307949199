import React, { useEffect, useState } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { useAction } from "../../context/action";
import { useJenna } from "../../context/jenna";


export default function JennaNotifier({ ...props })
{
    const images = {
        NOMESSAGE: '/jenna/SVG/listening.svg',
        MESSAGE: '/jenna/SVG/question.svg'
    }
    const { message, dismissMessage } = useJenna();
    const { dispatchAction } = useAction();
    const [image, setImage] = useState(images.NOMESSAGE)
    const [cssClass, setcssClass] = useState(' ');

    useEffect(() =>
    {
        fadeOut();
        setTimeout(() =>
        {
            setImage(message ? images.MESSAGE : images.NOMESSAGE);
            fadeIn();
        }, 200);
    }, [images.MESSAGE, images.NOMESSAGE, message])

    function fadeIn()
    {
        setcssClass('fadeIn');
    }

    function fadeOut()
    {
        setcssClass('fadeOut');
    }


    function buildPopOver(message = '', action, actionBtnText)
    {
        return <Popover id="popover-basic" style={{ backgroundColor: '#f9f9ff', borderRadius: '20px', padding: '5px', cursor: 'pointer', borderColor: '#f9f9ff' }} onClick={dismissMessage}>

            <Popover.Body>
                {message}
            </Popover.Body>
            {(action && actionBtnText &&
                <div className="col">
                    <button onClick={() => dispatchAction(action)} >{actionBtnText}</button>
                </div>)}

        </Popover>

    }


    return (<OverlayTrigger trigger='focus' placement="right" show={message !== null} overlay={buildPopOver(message)}>
        <i> <img className={cssClass} src={image} style={{ height: '90px', width: '90px' }} alt="Jenna" />{message && <span class="badge badge-warning">!</span>}</i>

    </OverlayTrigger>)
}

