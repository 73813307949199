import React, { useEffect, useState } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import Pagetitle from "../elements/Pagetitle";
import { useJenna } from '../../context/jenna'
import { Alert } from "react-bootstrap";
import Axios from "axios";


const SEND_TIMEOUT = 3000;
const ALERT_TIMEOUT = 5000;

function Contact()
{
  const [formdata, setFormdata] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });


  const { showMessage } = useJenna();

  const [alert, setAlert] = useState(null);

  useEffect(() =>
  {
    if (alert) {
      const timeout = setTimeout(() =>
      {
        setAlert(null)
        showMessage(null);
      }, ALERT_TIMEOUT)
      return () => clearTimeout(timeout);
    }
  }, [alert])

  const sendMessage = async (data) =>
  {

    try {
      const prodURL = "https://us-central1-breathein-e02cf.cloudfunctions.net/contactMe"
      console.log(formdata)
      await Axios.post(prodURL, formdata, {
        headers: {
          'Content-Type': 'application/json'
        }
      })

    } catch (error) {

    } finally {
      setAlert({
        variant: 'success',
        message: 'Your message has been sent'
      })
      const name = formdata.name.split(' ')[0];
      showMessage('Hey ' + name + "! I've sent your message to Jerry. He'll be contacting you as soon as he can.")
    }


  }

  const submitHandler = (event) =>
  {
    event.preventDefault();
    setAlert({
      message: 'Sending your message....',
      variant: 'warning'
    })
    if (!formdata.name) {
      setAlert({
        message: "name is required",
        variant: 'danger'
      });
    } else if (!formdata.email) {
      setAlert({
        message: "Email is required",
        variant: 'danger'
      });
    } else if (!formdata.subject) {
      setAlert({
        message: "Subject is required",
        variant: 'danger'
      });
    } else if (!formdata.message) {
      setAlert({
        message: "Message is required",
        variant: 'danger'
      });
    } else {
      sendMessage(formdata);
    }
  };

  const handleChange = (event) =>
  {
    setFormdata({
      ...formdata,
      [event.currentTarget.name]: event.currentTarget.value,
    });
  };

  return (
    <section id="contact" data-analytics-view="section-contact">
      <div className="container">
        <Pagetitle title="Get in Touch" />

        <div className="row">
          <div className="col-md-4">
            <div className="contact-info">
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
                <h3>Let's talk about everything!</h3>
              </ScrollAnimation>
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
                <p>
                  Don't like forms? Send me an{" "}
                  <a href="mailto:jerin.sebastian153@gmail.com">email</a>. 👋
                </p>
              </ScrollAnimation>
            </div>
          </div>

          <div className="col-md-8">
            <form
              id="contact-form"
              className="contact-form mt-6"
              onSubmit={submitHandler}
            >
              <div className="row">
                <div className="column col-md-6">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      id="InputName"
                      placeholder="Your name"
                      required
                      onChange={handleChange}
                      value={formdata.name}
                    />
                  </div>
                </div>

                <div className="column col-md-6">
                  <div className="form-group">
                    <input
                      type="email"
                      className="form-control"
                      id="InputEmail"
                      name="email"
                      required
                      placeholder="Email address"
                      onChange={handleChange}
                      value={formdata.email}
                    />
                  </div>
                </div>

                <div className="column col-md-12">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      id="InputSubject"
                      name="subject"
                      required
                      placeholder="Subject"
                      onChange={handleChange}
                      value={formdata.subject}
                    />
                  </div>
                </div>

                <div className="column col-md-12">
                  <div className="form-group">
                    <textarea
                      name="message"
                      id="InputMessage"
                      className="form-control"
                      rows="5"
                      required
                      placeholder="Message"
                      onChange={handleChange}
                      value={formdata.message}
                    ></textarea>
                  </div>
                </div>
              </div>
              <button
                type="submit"
                name="submit"
                id="submit"
                value="Submit"
                className="btn btn-default"
              >
                Send Message
              </button>
            </form>
            {alert && <Alert variant={alert.variant}>{alert.message}</Alert>}
          </div>
        </div>
      </div>
    </section>
  );
}



export default Contact;
