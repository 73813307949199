import React from "react";
import { Element } from "react-scroll";
import EstimateBox from "../elements/EstimateBox";
import Pagetitle from "../elements/Pagetitle";

export const ANDROID = 0;
export const WEB = 1;
export const BACKEND = 2;


export const estimateItems = [
    {
        key: 'web_2',
        currency: '$',
        icon: './images/responsive.png',
        section: 'section-estimate-web',
        title: 'Web Application',
        content: 'Want a responsive website for your new startup? Or maybe a highly advanced realtime web application hosted in your servers. I can help you build any kind of web application using React/Next.js. Feel free to try my Web app cost estimator to get a head start.',
        estimators: [
            {
                key: 'web_est_1',
                type: 'toggle',
                unitPrice: 100,
                defaultValue: false,
                label: 'Authentication/User Management'
            },
            {
                key: 'web_est_2',
                type: 'toggle',
                unitPrice: 100,
                defaultValue: false,
                label: 'Location Awareness (GPS)'
            },
            {
                key: 'web_est_3',
                type: 'toggle',
                unitPrice: 100,
                defaultValue: false,
                label: 'Realtime messaging'
            },

            {
                key: 'web_est_4',
                type: 'toggle',
                unitPrice: 100,
                defaultValue: false,
                label: 'Payment Processing'
            },
            {
                key: 'web_est_5',
                type: 'slider',
                unitPrice: 100,
                defaultValue: 5,
                label: 'No. of Pages',
                min: 5,
                max: 50,
                step: 5
            }
        ]
    },
    {
        key: 'android_1',
        currency: '$',
        section: 'section-estimate-android',
        icon: './images/social-media.png',
        title: 'Android Application',
        content: 'Looking for an Android application for your next dream project? Your idea could be the next Uber/Facebook/Snapchat. Feel free to try my android app cost estimator to get a head start.',
        estimators: [
            {
                key: 'android_est_1',
                type: 'toggle',
                unitPrice: 100,
                defaultValue: false,
                label: 'Authentication/User Management'
            },
            {
                key: 'android_est_2',
                type: 'toggle',
                unitPrice: 100,
                defaultValue: false,
                label: 'Location Awareness/GPS'
            },
            {
                key: 'android_est_3',
                type: 'toggle',
                unitPrice: 100,
                defaultValue: false,
                label: 'Realtime Messaging/Chat'
            },
            {
                key: 'android_est_4',
                type: 'toggle',
                unitPrice: 100,
                defaultValue: false,
                label: 'Payment Processing'
            },
            {
                key: 'android_est_5',
                type: 'slider',
                unitPrice: 10,
                defaultValue: 5,
                label: 'No. of Screens',
                min: 5,
                max: 50,
                step: 5
            },

        ]
    },

    {
        key: 'backend_1',
        currency: '$',
        icon: './images/data-lake.png',
        title: 'Backend APIs',
        section: 'section-estimate-backend',
        content: 'Want a distributed backend for your application. I can help you build some serious scalable backend. Feel free to try my backend cost estimator to get a head start.',
        estimators: [
            {
                key: 'backend_est_1',
                type: 'toggle',
                unitPrice: 100,
                defaultValue: false,
                label: 'RESTful APIs with JWT Authorization'
            },
            {
                key: 'backend_est_2',
                type: 'toggle',
                unitPrice: 100,
                defaultValue: false,
                label: 'GraphQL APIs'
            },
            {
                key: 'backend_est_3',
                type: 'toggle',
                unitPrice: 100,
                defaultValue: false,
                label: 'WebSocket APIs'
            },
            {
                key: 'backend_est_5',
                type: 'toggle',
                unitPrice: 100,
                defaultValue: false,
                label: 'Deploy backend on AWS'
            },
            {
                key: 'backend_est_4',
                type: 'slider',
                unitPrice: 0.002,
                defaultValue: 50000,
                label: 'Daily Active Users (DAU)',
                min: 50000,
                max: 1000000,
                step: 50000
            }
        ]
    },
]

//test

export default function Estimate()
{



    return (
        <section id="estimate" data-analytics-view="section-estimate">
            <div className="container">
                <Pagetitle title="Create an Estimate" />

                <div className="row">

                    {
                        estimateItems.map((item, index) => <Element key={item.key} name={item.section}>
                            <EstimateBox estimateBoxItem={item} reversed={index % 2 === 0} />
                        </Element>)
                    }
                </div>
                <div className="spacer" data-height="70"></div>

            </div>
        </section>
    );
}