import React, { useEffect, useState } from "react";
import { Link } from "react-scroll";
import { useJenna } from "../../context/jenna";


const DEBOUNCE_TIMEOUT = 5000;
const CONFIDENCE = 10;

export default function EstimateBox({ estimateBoxItem, reversed = false, isJenna = false })
{
    const [price, setPrice] = useState(0);
    const [sliderPrice, setSlidePrice] = useState(0);
    const { icon, title, content, estimators, currency } = estimateBoxItem;
    const { showJenna } = useJenna();


    if (isJenna) {
        return (<div className="col" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>

            {
                estimators && estimators.map(estimator =>
                {
                    if (!estimator)
                        return <></>;
                    else if (estimator.type === 'toggle')
                        return <SingleSwitch estimator={estimator} onValueChange={(value) => setPrice(Math.max(price + value, 0))} />
                    else if (estimator.type === 'slider')
                        return <SingleSlider sliderContent={estimator} onValueChange={(value) => setSlidePrice(value)} />
                    return <></>
                })
            }
            <h3 className="price text-center my-2">
                <em>{currency}</em>
                {price + sliderPrice}

            </h3>
            <div className="mt-4 text-center">
                <Link
                    className="btn btn-default mx-3"
                    to="section-contact"
                    spy={true}
                    smooth={true}
                    duration={500}
                >
                    Get a detailed quote
                </Link>

            </div>
        </div>)
    }

    return <div className="row m-3" style={{ display: 'flex' }}>

        {!reversed && <div className="col-md-3">
            <div className="text-center text-md-left" style={{ height: "100%", alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                <img className="img-res" src={icon} alt={title} />
            </div>
            <div className="spacer d-md-none d-lg-none" data-height="30"></div>
        </div>}


        <div className="col-md-9">
            <div className="rounded bg-white shadow-dark padding-30">
                <div className="row">
                    <div className="col-md-6" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'start' }}>
                        <h4 className="mb-3 mt-0">{title}</h4>
                        <p>{content}</p>
                        <button className="btn btn-text" onClick={() => showJenna('fragment-chat')}>Have a query? Ask Jenna.</button>
                    </div>
                    <div className="col-md-6" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>

                        {
                            estimators && estimators.map(estimator =>
                            {
                                if (!estimator)
                                    return <></>;
                                else if (estimator.type === 'toggle')
                                    return <SingleSwitch key={estimator.key} estimator={estimator} onValueChange={(value) => setPrice(Math.max(price + value, 0))} />
                                else if (estimator.type === 'slider')
                                    return <SingleSlider key={estimator.key} sliderContent={estimator} onValueChange={(value) => setSlidePrice(value)} />
                                return <></>
                            })
                        }
                        <h3 className="price text-center my-2">
                            <em>{currency}</em>
                            {price + sliderPrice}

                        </h3>
                        <div className="mt-4 text-center">
                            <Link
                                className="btn btn-default mx-3"
                                to="section-contact"
                                spy={true}
                                smooth={true}
                                duration={500}
                            >
                                Get a detailed quote
                            </Link>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        {reversed && <div className="col-md-3">
            <div className="text-center text-md-left" style={{ height: "100%", alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                <img className="img-res" src={icon} alt={title} />
            </div>
            <div className="spacer d-md-none d-lg-none" data-height="30"></div>
        </div>}
    </div>

}


export function SingleSlider({ sliderContent, onValueChange = () => { } })
{

    const [value, setValue] = useState(sliderContent.defaultValue || 0);
    useEffect(() =>
    {
        onValueChange && onValueChange(sliderContent.unitPrice * value);

    }, [value, onValueChange, sliderContent.unitPrice])

    function handleOnValueChange(e)
    {
        setValue(e.target.value);
    }

    return <div id={sliderContent.key} className="text-center my-2" style={{ display: 'flex', flexDirection: 'column' }} >
        <label htmlFor={sliderContent.key} className="form-label">{sliderContent.label} ({value})</label>
        <input type="range" className="form-range" style={{ cursor: 'pointer' }} value={value} min={sliderContent.min} max={sliderContent.max} onChange={handleOnValueChange} step={sliderContent.step} id={sliderContent.key} />
    </div>
}

export function SingleSwitch({ estimator, onValueChange = () => { } })
{

    return <div key={estimator.key} className="form-check form-switch" >
        <input className="form-check-input" style={{ cursor: 'pointer' }} onChange={(e) => onValueChange(e.target.checked ? estimator.unitPrice : (-1 * estimator.unitPrice))} type="checkbox" id={estimator.key} />
        <label className="form-check-label" htmlFor={estimator.key} style={{ cursor: 'pointer' }}>
            {estimator.label}
        </label>
    </div>
}