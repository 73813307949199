
import React, { useState } from "react";
import { useJenna } from "../../context/jenna";
import Modal from "../elements/Modal";
import Pagetitle from "../elements/Pagetitle";
import Timeline from "../elements/Timeline";

const educationData = [
    {
        id: 1,
        title: "Apply",
        years: "Step-I",
        content:
            "Prepare a detailed pitch of your project. Mention how you are planning to implement it, and a detailed timeline. Upload it to my cloud using the button below. If your project is selected, you will be contacted via your email.",
    },
    {
        id: 2,
        title: "Set up repo",
        years: "Step-II",
        content:
            "If you don't have a GitHub account, I strongly recommend you to have one. After creating a GitHub account, create a new repo and add me (JerrySJoseph) as a contributor.",
    },
    {
        id: 3,
        title: "Work! Work! and Work!",
        years: "Final Step",
        content:
            "I believe a startup/project is more implementation than just an idea. We will work together to build applications that may one day make this world a better place.",
    },
];

export default function Community()
{
    const [modalOpen, setModalOpen] = useState(false);
    const [name, setName] = useState();
    const { showMessage } = useJenna();

    function handleFormSubmit(e)
    {
        e.preventDefault();
        let timeout = setTimeout(() =>
        {
            setModalOpen(false);
            showMessage('Hey ' + name.split(' ')[0] + "! I've sent your project proposal to Mr. Jerry. He will review as soon as possible.")
            clearTimeout(timeout);
            timeout = setTimeout(() => showMessage(null) || clearTimeout(timeout), 5000);
        }, 1000);

    }

    return (
        <section id="community" data-analytics-view="section-community">
            <div className="container">
                <Pagetitle title="Community Projects" />
                <div className="row">
                    <div className="col">
                        <div id="intro" className=" community-intro bg-white rounded shadow-dark padding-30 overflow-hidden ">
                            <h5>Just testing out your idea?</h5>
                            <p>If you have got a wonderful idea and is looking
                                for someone to help you make it a reality, you might just be at the right place. I work on new promising projects every year. Feel free to join the
                                community and let me know about your idea. If you make the cut, your dreams will no longer be just dreams! However, I'm gonna need you to buy me coffee
                                whenever we meet.
                            </p>
                            <div className="timeline">
                                {educationData.map((education) => (
                                    <Timeline key={education.id} education={education} />
                                ))}
                                <span className="line"></span>
                            </div>
                            <div className="mt-4 text-center">
                                <button id="btn-contest-model-toggle" name="btn-contest-model-toggle" className="btn btn-default" onClick={() => setModalOpen(true)}>
                                    Apply for contest
                                </button>
                                <Modal show={modalOpen} onClose={() => setModalOpen(false)}>
                                    <form

                                        onSubmit={handleFormSubmit}>

                                        <div className="bg-white rounded shadow-dark padding-30 overflow-hidden ">
                                            <div style={{ display: 'flex', marginBottom: 20 }}>
                                                <h5>Create your proposal</h5>
                                                <div style={{ flexGrow: 1 }} />
                                                <i className="fa fa-times" style={{ cursor: 'pointer' }} onClick={() => setModalOpen(false)} aria-hidden="true"></i>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="name"
                                                            required
                                                            id="InputName"
                                                            placeholder="Your name"
                                                            onChange={(e) => setName(e.target.value)}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <input
                                                            type="email"
                                                            className="form-control"
                                                            id="InputEmail"
                                                            required
                                                            name="email"
                                                            placeholder="Email address"

                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="InputProjectName"
                                                            required
                                                            name="projectName"
                                                            placeholder="Name of your project"

                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <textarea
                                                            name="detail"
                                                            id="InputDetail"
                                                            required
                                                            className="form-control"
                                                            rows="5"
                                                            placeholder="A brief explanation about how your idea solves a problem in today's world."

                                                        ></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                            <button id="btn-apply-contest" name="btn-apply-contest" type='submit' className="apply-btn btn btn-default mt-2">
                                                Submit
                                            </button>
                                        </div>
                                    </form>
                                </Modal>
                            </div>

                        </div>


                    </div>
                </div>
            </div>



        </section>
    );
}
