import React, { useContext, useEffect, useState } from 'react';

export const ActionContext = React.createContext();

export function useAction()
{
    return useContext(ActionContext);
}


export default function ActionProvider({ children })
{
    const [currentAction, setCurrentAction] = useState(null);

    function dispatchAction(_actionName)
    {
        setCurrentAction(_actionName);
    }

    useEffect(() =>
    {
        if (currentAction != null)
            alert('Current Action :' + currentAction);
    }, [currentAction])

    const value = {
        currentAction,
        dispatchAction,
    }

    return <ActionContext.Provider value={value}>
        {children}
    </ActionContext.Provider>
}