import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
//import './analytics/analytics';
import "./App.scss";
import ActionProvider from "./context/action";
import JennaProvider from "./context/jenna";
import Homepage from "./pages/Homepage";
import useAnalytics from "./analytics/useAnalytics";



function App()
{

  useAnalytics({
    projectId: process.env.NODE_ENV === 'production' ? '670d0dc60be246a63b4743e2' : '670d8bf2990673f49043ace4',
  })

  return (
    <BrowserRouter>
      <ActionProvider>
        <JennaProvider>
          <Switch>
            <Route path="/" exact>
              <Homepage />
            </Route>

          </Switch>
        </JennaProvider>
      </ActionProvider>
    </BrowserRouter>

  );
}

export default App;
