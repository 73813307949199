import React from "react";

function Footer({ light }) {
  return (
    <footer className={light ? "footer light" : "footer"}>
      <div className="container">
        <span className="copyright">
          &copy; {new Date().getFullYear()} Jerry S Joseph.
        </span>

      </div>
      <div className="container">
        <span className="copyright">
          &reg; All rights reserved to JSCloud Inc.
        </span>

      </div>
    </footer>
  );
}

export default Footer;
