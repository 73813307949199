import React from "react";

function Timeline({ education }) {
  const { years, title, content } = education;
  return (
    <div className="timeline-container m-2">
        <div className="content">
          <span className="time m-0">{years}</span>
          <h3 className="title m-0">{title}</h3>
          <p>{content}</p>
        </div>
      </div>
  );
}

export default Timeline;
