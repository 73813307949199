import React, { useContext, useEffect, useState } from 'react'


export const JennaContext = React.createContext();

export function useJenna()
{
    return useContext(JennaContext);
}

const LOCAL_SETTING_TAG = 'jenna_enabled';
const VA_DELAY_SECONDS = 3;

const ENABLED = 'ENABLED';
const DISABLED = 'DISABLED';

export default function JennaProvider({ children })
{
    const [open, setOpen] = useState(false);
    const [fragment, setFragment] = useState('fragment-intro');
    const [alert, setAlert] = useState(null);
    const [message, setMessage] = useState(null);
    const [warning, setWarning] = useState(null);
    const [communityTour, setCommunityTour] = useState(false);
    useEffect(() =>
    {
        if (isJennaEnabled()) {
            const timeout = setTimeout(() => setOpen(true), VA_DELAY_SECONDS * 1000);
            return () => clearTimeout(timeout);
        }

    }, [])

    const showJenna = (fragment) =>
    {
        setFragment(fragment);
        setOpen(true);
        setAlert(null);
        setMessage(null);
        setWarning(null);
    }

    const forceCloseJenna = () =>
    {
        localStorage.setItem(LOCAL_SETTING_TAG, DISABLED);
        setOpen(false);
    }

    function isJennaEnabled()
    {
        if (localStorage.getItem(LOCAL_SETTING_TAG) === null || localStorage.getItem(LOCAL_SETTING_TAG) === undefined)
            return true;
        return localStorage.getItem(LOCAL_SETTING_TAG) === ENABLED;

    }

    function startCommunityTour()
    {
        setCommunityTour(true);
    }

    function showAlert(msg)
    {
        setAlert(msg);
    }

    function dismissAlert()
    {
        setAlert(null);
    }

    function showWarning(msg)
    {
        setWarning(msg);
    }

    function dismissWarning(msg)
    {
        setWarning(null);
    }

    function showMessage(msg)
    {
        setMessage(msg);
    }

    function dismissMessage()
    {
        setMessage(null);
    }

    const value = {
        open,
        alert,
        message,
        warning,
        fragment,
        communityTour,
        startCommunityTour,
        setOpen,
        setFragment,
        showJenna,
        forceCloseJenna,
        showAlert,
        dismissAlert,
        showWarning,
        dismissWarning,
        showMessage,
        dismissMessage
    }

    return <JennaContext.Provider value={value}>
        {children}
    </JennaContext.Provider>
}