import React from "react";
import TrackVisibility from "react-on-screen";
import Counter from "../elements/Counter";
import Pagetitle from "../elements/Pagetitle";
import Skill from "../elements/Skill";

const aboutContent = {
  name: "Jerin Sebastian",
  avatarImage: "/images/jerry.png",
  content:
    "Hey, I am Jerin, a software developer from Brighton, United Kingdom. I have rich experience in building Web and Android applications." +
    " I am into some serious software building business and I am always on the lookout for revolutionary ideas and wonderful projects." +
    " If you have any of those, feel free to ping me.",
};

const progressData = [
  {
    id: 1,
    title: "Frontend Development",
    percantage: 95,
    progressColor: "#FFD15C",
    icon: '/images/apps.png'
  },
  {
    id: 2,
    title: "Backend Development",
    percantage: 95,
    progressColor: "#FF4C60",
    icon: '/images/server.png'
  },
  {
    id: 3,
    title: "Cloud Platforms (AWS/GCP/Azure)",
    percantage: 100,
    progressColor: "#6C6CE5",
    icon: '/images/cloud_platform.png'
  },
];

const counterData = [
  {
    id: 1,
    title: "Projects completed",
    count: 27,
    icon: "icon-fire",
  },
  {
    id: 2,
    title: "Cups of coffee",
    count: 2700,
    icon: "icon-cup",
  },
  {
    id: 3,
    title: "Satisfied clients",
    count: 27,
    icon: "icon-people",
  },
  {
    id: 4,
    title: "Certifications",
    count: 5,
    icon: "icon-badge",
  },
];

function About()
{
  return (
    <section id="about" data-analytics-view="section-about">
      <div className="container">
        <Pagetitle title="About Me" />
        <div className="row">
          <div className="col-md-2 d-flex justify-content-center" >
            <div className="avatar-small text-center text-md-right">
              <img src="/images/jerry.png" alt='Jerry S Joseph' height={120} width={120} />
            </div>

          </div>

          <div className="col-md-10 triangle-left-md triangle-top-sm">
            <div className="rounded bg-white shadow-dark padding-30">
              <div className="row">
                <div className="col-md-6">
                  <p>{aboutContent.content}</p>
                  <div className="mt-3">
                    {/* <a href="./resume/Jerin_Sebastian_Resume.pdf" className="btn btn-default">
                      <span><i className="fa fa-download mr-2" aria-hidden="true"></i></span> Download CV
                    </a> */}
                  </div>
                  <div
                    className="spacer d-md-none d-lg-none"
                    data-height="30"
                  ></div>
                </div>
                <div className="col-md-6">
                  {progressData.map((progress) => (
                    <TrackVisibility
                      once
                      key={progress.id}
                      className="progress-wrapper"
                    >
                      <Skill progress={progress} />
                    </TrackVisibility>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="spacer" data-height="70"></div>
        <div className="row fix-spacing">
          {counterData.map((counter) => (
            <div key={counter.id} className="col-md-3 col-sm-6">
              <TrackVisibility once>
                <Counter counterItem={counter} />
              </TrackVisibility>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default About;
