import { useEffect, useRef, useCallback } from 'react';

/**
 * Calculate duration between two ISO formatted date strings.
 * 
 * @param {string} isoDate1 - Start time.
 * @param {string} isoDate2 - End Time.
 * @returns {number} duration in ms
 */
function calculateDuration(isoDate1, isoDate2)
{
    // Convert ISO date strings to Date objects
    const date1 = new Date(isoDate1);
    const date2 = new Date(isoDate2);

    // Get the time in milliseconds since the UNIX epoch for both dates
    const time1 = date1.getTime();
    const time2 = date2.getTime();

    // Calculate the difference in milliseconds
    const durationInMs = Math.abs(time2 - time1);

    return durationInMs;
}

/**
 * Custom hook for tracking user activity and sending analytics events.
 * 
 * @param {Object} config - Configuration object for analytics.
 * @param {string} [config.apiBaseUrl] - The base URL of the analytics API.
 * @param {string} [config.projectId] - The project ID for tracking.
 * @param {number} [config.activityTrackingTimeout] - Timeout for user inactivity (in milliseconds).
 * @param {number} [config.eventBatchSize] - Number of events before flushing.
 * @param {number} [config.eventBatchInterval] - Time interval for batch flushing (in milliseconds).
 * @param {number} [config.maxSessionRetentionDuration] - Max duration a session will be retained in case of page navigation and reloads (in milliseconds).
 * 
 * @returns {Object} - Returns `logEvent` function to log events and `flushEvents` to manually flush event queue.
 */
const useAnalytics = (config = {}) =>
{
    const isDev = process.env.NODE_ENV !== 'production';
    const log = isDev ? console.log : () => { };
    const error = isDev ? console.error : () => { };

    const sessionIdRef = useRef(null);  // useRef to avoid re-renders
    const eventQueue = useRef([]);
    const activityTimeout = useRef(null);

    const API_BASE_URL = config.apiBaseUrl || `${ !isDev ? 'https://analytics.jscloud.in' : 'http://localhost:3000' }/api/v1`;
    const PROJECT_ID = config.projectId;
    const ACTIVITY_TRACKING_TIMEOUT = config.activityTrackingTimeout || (!isDev ? 15 * 60 * 1000 : 5 * 60 * 1000); // 15 mins or 5 mins
    const BATCH_SIZE = config.eventBatchSize || 10;
    const BATCH_INTERVAL = config.eventBatchInterval || 10 * 1000; // 10s
    const MAX_SESSION_RETENTION_DURATION = config.maxSessionRetentionDuration || (!isDev ? 60 * 1000 : 10 * 1000); //60s or 10s


    /**
     * Helper function to send an API request.
     * 
     * @param {string} url - The API endpoint URL.
     * @param {string} method - HTTP method (e.g., 'POST', 'PUT').
     * @param {Object} data - Data to be sent in the request body.
     * 
     * @returns {Promise<Object|null>} - The response from the API or null in case of an error.
     */

    const apiRequest = useCallback(async (url, method, data) =>
    {

        try {

            const response = await fetch(url, {
                method,
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(data),
            });
            if (!response.ok) {
                error(`${ response.status } [${ method }] ${ url }`, data);
                throw new Error('API request failed')
            }
            log(`${ response.status } [${ method }] ${ url }`, data);
            return await response.json();
        } catch (err) {
            error('Analytics API Error:', err);
            return null;
        }
    }, [error]);

    // HELPER: Generate Visitor ID
    const getVisitorId = useCallback(() =>
    {

        let visitorId = localStorage.getItem('visitorId');
        if (!visitorId) {
            visitorId = 'visitor-' + Math.random().toString(36).substr(2, 9);
            localStorage.setItem('visitorId', visitorId);
        }
        return visitorId;
    }, []);

    // HELPER: Generate Visitor ID
    const getRandomId = useCallback(() => 'visitor-' + Math.random().toString(36).substr(2, 16), []);

    // HELPER: Flush events
    const flushEvents = useCallback(async () =>
    {

        if (!sessionIdRef.current || eventQueue.current.length === 0) return;
        log(`Flushing ${ eventQueue.current.length } events.`);

        const url = `${ API_BASE_URL }/events/log`;
        const result = await apiRequest(url, 'POST', { events: eventQueue.current });
        if (result && result.success) {
            eventQueue.current = [];
        }
    }, [API_BASE_URL, apiRequest, log]);

    // HELPER: Log event and flush when the queue reaches BATCH_SIZE
    const logEvent = useCallback((event) =>
    {
        if (!sessionIdRef.current) return;

        eventQueue.current.push(event);
        log('Logged event', event);
        if (eventQueue.current.length >= BATCH_SIZE) flushEvents();
    }, [BATCH_SIZE, flushEvents, log]);


    /****************************************** Element View Tracking **************************** */

    let sectionTimers = useRef({}); // To store start times and durations of visible sections

    // Helper function to log a section view with time spent
    function logSectionView(sectionId, timeSpent)
    {
        const eventData = {
            visitorId: getVisitorId(),
            session: sessionIdRef.current,
            project: PROJECT_ID,
            eventType: 'view',
            eventName: 'view-' + sectionId,
            eventTarget: sectionId,
            eventAttributes: {
                duration: timeSpent,
            },
            eventTime: new Date().toISOString(),
        };

        logEvent(eventData); // Batch or send to the server
    }

    // Function to start tracking time for a visible section
    function startTracking(sectionId)
    {
        if (!sectionTimers.current[sectionId]) {
            sectionTimers.current[sectionId] = { startTime: Date.now(), duration: 0 };
        }
        log(sectionTimers.current)
    }

    // Function to stop tracking time for a section when it leaves the viewport
    function stopTracking(sectionId)
    {
        if (sectionTimers.current[sectionId]) {
            const duration = (Date.now() - sectionTimers.current[sectionId].startTime) / 1000; // Convert ms to seconds
            sectionTimers.current[sectionId].duration += duration;
            logSectionView(sectionId, sectionTimers.current[sectionId].duration);
            delete sectionTimers.current[sectionId]; // Clear once logged

        }
    }

    const observerCallback = (entries) =>
    {

        entries.forEach(entry =>
        {
            const sectionId = entry.target.getAttribute('data-analytics-view'); // Each section should have an id

            if (!sectionId || sectionId === 'true')
                throw new Error('No valid view id specified for element', entry.target.tagName)

            if (entry.isIntersecting) {
                log(sectionId, 'is in view')
                // Section enters viewport
                startTracking(sectionId);
            } else {
                log(sectionId, 'is out of view')
                // Section leaves viewport
                stopTracking(sectionId);
            }
        });
    }




    /*************************************************************************************************************** */





    // HELPER: saving and retrieving session id from localstorage
    const saveSessionId = useCallback((id) => window.localStorage.setItem('sessionId', id), []);
    const retrieveSessionId = useCallback(() => window.localStorage.getItem('sessionId'), []);
    const clearSessionId = useCallback(() => saveSessionId(null), [saveSessionId]);


    // HELPER: End the session
    const endSession = useCallback(async () =>
    {
        if (!sessionIdRef.current) return;

        //add view queue which are left        
        if (Object.keys(sectionTimers.current).length > 0) {
            Object.keys(sectionTimers.current).forEach(sectionId =>
            {
                const duration = (Date.now() - sectionTimers.current[sectionId].startTime) / 1000; // Convert ms to seconds
                sectionTimers.current[sectionId].duration += duration;
                logSectionView(sectionId, sectionTimers.current[sectionId].duration);
                //delete sectionTimers[sectionId];
            })
        }

        const url = `${ API_BASE_URL }/session/end/${ sessionIdRef.current }`;
        const data = { events: eventQueue.current };

        if (navigator.sendBeacon) {
            log('Ending session (Beacon)', sessionIdRef.current);
            const blob = new Blob([JSON.stringify(data)], { type: 'application/json' });
            navigator.sendBeacon(url, blob);
        } else {
            log('Ending session (POST)', sessionIdRef.current);
            await apiRequest(url, 'POST', data);
        }

        //sessionIdRef.current = null;
        //clearSessionId();

    }, [API_BASE_URL, apiRequest]);


    // HELPER: Reset Activity Timer
    const resetActivityTimer = useCallback(() =>
    {
        if (activityTimeout.current) clearTimeout(activityTimeout.current);
        activityTimeout.current = setTimeout(async () =>
        {
            log('Triggered Activity Timeout')
            await endSession();
        }, ACTIVITY_TRACKING_TIMEOUT);
    }, [ACTIVITY_TRACKING_TIMEOUT, endSession]);

    const trackUserActivity = useCallback(() =>
    {
        ['mousemove', 'keydown', 'scroll', 'click'].forEach(eventType =>
        {
            window.addEventListener(eventType, resetActivityTimer);
        });
    }, [resetActivityTimer]);

    // HELPER: Create a new session
    const createSession = useCallback(async () =>
    {
        if (sessionIdRef.current) return;  // Avoid creating if session already exists

        if (sessionIdRef.current === 'pending') return;  // Avoid race condition

        sessionIdRef.current = 'pending';
        clearSessionId();

        log('Creating new session for visitorId:', getVisitorId())
        const sessionData = {
            visitorId: getVisitorId(),
            project: PROJECT_ID,
            referrer: document.referrer || 'Direct',
            pageUrl: window.location.pathname,
            pageTitle: document.title,
            userAgent: navigator.userAgent,
        };

        const result = await apiRequest(`${ API_BASE_URL }/session/create`, 'POST', sessionData);
        if (result && result.sessionId) {
            sessionIdRef.current = result.sessionId;
            saveSessionId(result.sessionId);
            resetActivityTimer();
        }
    }, [API_BASE_URL, PROJECT_ID, apiRequest, getVisitorId, resetActivityTimer]);

    // HELPER: Update the session (for page views)
    const updateSession = useCallback(async () =>
    {
        sessionIdRef.current = retrieveSessionId();
        if (!sessionIdRef.current) return createSession();

        const updateData = {
            exitPage: window.location.pathname,
            pageUrl: window.location.pathname,
            pageTitle: document.title,
            referrer: document.referrer || 'Direct',
        };

        await apiRequest(`${ API_BASE_URL }/session/update/${ sessionIdRef.current }`, 'PUT', updateData);
        resetActivityTimer();
    }, [API_BASE_URL, apiRequest, createSession]);


    // HELPER: Check if an element should be tracked
    const isRelevantElement = useCallback((element) =>
    {
        const tagName = element.tagName.toLowerCase();
        const isMarkedForAnalytics = element.getAttribute('data-analytics-event') === 'true';
        return (
            isMarkedForAnalytics ||
            tagName === 'button' ||
            tagName === 'a' ||
            (tagName === 'input' && (element.type === 'submit' || element.type === 'button'))
        );
    }, []);

    useEffect(() =>
    {
        if (!PROJECT_ID) {
            throw new Error('No project-id specified. Please specify Project id in the global config.');
        }

        log('API base url', API_BASE_URL);
        log('Project Id', PROJECT_ID);
        log('Batch interval', BATCH_INTERVAL);
        log('Activity Tracking timeout', ACTIVITY_TRACKING_TIMEOUT);


        // // HANDLER: Document visibility change (for session creation)
        const handleVisibilityChange = async () =>
        {
            // if user switch tabs try updating the session if possible
            if (document.visibilityState === 'visible') {
                await updateSession();
            }
            else if (document.visibilityState === 'hidden') {
                await endSession();
            }
        };

        // HANDLER: Document click event tracking
        const handleDocumentClick = (ev) =>
        {
            let element = ev.target;
            const tagName = element.tagName.toLowerCase();

            if (tagName === 'i' && element.parentElement) element = element.parentElement; // fix for icons wrapped in <i>

            if (!isRelevantElement(element)) return;

            const eventName = element.getAttribute('data-event-name') || 'Click Event';
            const eventType = element.getAttribute('data-event-type') || 'click';

            const eventData = {
                visitorId: getVisitorId(),
                session: sessionIdRef.current,
                project: PROJECT_ID,
                eventType,
                eventName,
                eventTarget: element.id || element.name || 'unnamed element',
                elementType: tagName,
                eventAttributes: {
                    innerText: element.innerText,
                    value: element.value,
                },
                eventTime: new Date().toISOString(),
            };

            logEvent(eventData);
        };

        const handleOnLoad = async (ev) =>
        {
            const currentPage = window.location.href;
            const lastPage = window.localStorage.getItem('page');
            const timeEnd = new Date().toISOString();
            const timeStart = window.localStorage.getItem('time');

            const elapsedTime = calculateDuration(timeStart, timeEnd);
            // first visit
            if (!lastPage || !timeStart) {
                log('Detected First Visit')
                await createSession();
            }
            //navigate to other page
            else if (currentPage !== lastPage) {

                // if navigated to other page after the session retention time limit, log it as new session, otherwise update current session
                if (elapsedTime <= MAX_SESSION_RETENTION_DURATION) {
                    log('Detected navigate')
                    await updateSession();
                } else {
                    log('Detected navigate after time period')
                    await createSession();
                }

            }
            // reload or coming to same page
            else if (currentPage === lastPage) {

                // if coming to same page after the session retention time limit, log it as new session, otherwise update current session
                if (elapsedTime <= MAX_SESSION_RETENTION_DURATION) {
                    log('Detected reload')
                    await updateSession();
                }
                else {
                    log('Detected reload after time period')
                    await createSession();
                }


            }

            window.localStorage.removeItem('page');
            window.localStorage.removeItem('time');

        }


        const handleBeforeUnload = async (ev) =>
        {
            window.localStorage.setItem('page', window.location.href);
            window.localStorage.setItem('time', new Date().toISOString());

            await endSession();
        }

        // Register event listeners
        window.addEventListener('load', handleOnLoad);
        window.addEventListener('visibilitychange', handleVisibilityChange);
        window.addEventListener('beforeunload', handleBeforeUnload);
        document.addEventListener('click', handleDocumentClick);

        // Set interval for flushing events
        const batchIntervalId = setInterval(flushEvents, BATCH_INTERVAL);

        trackUserActivity();

        // Setting up Intersection Observer for tracking visibility
        const observer = new IntersectionObserver(observerCallback, { threshold: 0.5 });

        // Apply the observer to all target sections/components
        const sections = document.querySelectorAll('[data-analytics-view]');
        sections.forEach(section =>
        {
            observer.observe(section);
        });

        // Cleanup listeners and intervals on unmount
        return () =>
        {
            window.removeEventListener('load', handleOnLoad);
            //window.removeEventListener('visibilitychange', handleVisibilityChange);
            window.removeEventListener('beforeunload', endSession);
            document.removeEventListener('click', handleDocumentClick);
            clearInterval(batchIntervalId);
            if (activityTimeout.current) clearTimeout(activityTimeout.current);
        };
    }, [API_BASE_URL, PROJECT_ID, ACTIVITY_TRACKING_TIMEOUT, BATCH_SIZE, BATCH_INTERVAL, resetActivityTimer, log, createSession, endSession, flushEvents, isRelevantElement, getVisitorId, logEvent, MAX_SESSION_RETENTION_DURATION, updateSession]);

    return { logEvent, flushEvents };
};

export default useAnalytics;
