const welcomeMessages = [
    "Hey there",
    "Howdy!",
    "Hello! its nice to meet you",
    "Namaste!",
    "Hi! nice to meet you",
    "It's a pleasure to meet you",
    "Hi!",
    "Hey, Whatsupp?"
]

export function getWelcomeMessage()
{
    return welcomeMessages[getRandomInt(0, welcomeMessages.length - 1)];
}


function getRandomInt(min, max)
{
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1) + min); //The maximum is inclusive and the minimum is inclusive
}