import React from "react";
import Slider from "react-slick";
import Pagetitle from "../elements/Pagetitle";
import Testimonial from "../elements/Testimonial";

const sliderData = [
  {
    id: 1,
    avatar: "images/reviews/jigyanshu.jpg",
    userName: "Jigyanshu Mohapatra",
    subtitle: "Web Platform for Laundry",
    review:
      "He did a wonderful job in building our platfrom. For sure will consider him for upcoming projects. Good luck! 👍",
  },
  {
    id: 2,
    avatar: "images/reviews/arman.jpg",
    userName: "Arman Khan",
    subtitle: "Android + Web + Backend for dating app",
    review:
      "Great experience with Jerry. Right from the start he took ownership of a project that was messy and worked with us to iron out all the bugs and did well to make recommendations where necessary. He also took the time and effort to resolve some complex issues. Nothing but the best. Will definitely work with him again",
  },
  {
    id: 3,
    avatar: "images/avatar-3.svg",
    userName: "Abhishek Kumar",
    subtitle: "Web + Android app for steganography",
    review:
      "He did his best work! And delivered a working platform. We are still busy with developing it further but it is in good hands",
  },
];

function Testimonials()
{
  const slidetSettings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <section id="testimonials" data-analytics-view="section-testimonials">
      <div className="container">
        <div className="col">
          <Pagetitle title="Clients & Review" />

        </div>

        <Slider {...slidetSettings} className="testimonials-wrapper">
          {sliderData.map((slider) => (
            <Testimonial key={slider.id} slider={slider} />
          ))}
        </Slider>
      </div>
    </section>
  );
}

export default Testimonials;
