import React, { useEffect, useRef, useState } from 'react';
import { getWelcomeMessage } from '../../api/JennaEgnine';
import { useJenna } from '../../context/jenna';
import Academics from '../sections/Academics';
import { ANDROID, BACKEND, estimateItems, WEB } from '../sections/Estimate';
import Experiences from '../sections/Experiences';
import Works from '../sections/Works';
import EstimateBox from './EstimateBox';
import JennaAvatar, { AVATAR } from './JennaAvatar';
import Modal from './Modal';
import { Link } from 'react-scroll';

const NOOP = () => { }

function Fragment({ fragment, ...props })
{
    let component = null;
    switch (fragment) {
        case "fragment-intro":
            component = <IntroFragment {...props} />;
            break;
        case 'fragment-selection':
            component = <SelectionFragment  {...props} />;
            break;
        case 'fragment-recruiter':
            component = <RecruiterFragment  {...props} />;
            break;
        case 'fragment-recruiter-education':
            component = <EducationFragment  {...props} />;
            break;
        case 'fragment-recruiter-work':
            component = <WorkFragment  {...props} />;
            break;
        case 'fragment-recruiter-certifications':
            component = <CertificationsFragment  {...props} />;
            break;
        case 'fragment-recruiter-projects':
            component = <ProjectFragment  {...props} />;
            break;
        case 'fragment-client':
            component = <ClientFragment  {...props} />;
            break;
        case 'fragment-client-android':
            component = <AndroidFragment  {...props} />;
            break;
        case 'fragment-client-web':
            component = <WebFragment  {...props} />;
            break;
        case 'fragment-client-backend':
            component = <BackendFragment  {...props} />;
            break;
        case 'fragment-client-upgrade':
            component = <UpgradeFragment  {...props} />;
            break;
        default:
            component = <IntroFragment  {...props} />;
    }
    return component;
}


export default function JennaVA()
{
    const { open, setOpen, fragment, setFragment, forceCloseJenna } = useJenna();
    const [_avatar, setAvatar] = useState(AVATAR.HELLO)

    function handleOnClose()
    {
        forceCloseJenna();
    }

    function handleAvatarChange(_newAvatar)
    {
        setAvatar(_newAvatar);
    }

    return <Modal show={open} onClose={handleOnClose}>
        <div className='white-popup modal-xl'>
            <div className='row'>
                <div className='col-md-12'>
                    <i role='button' className="fa fa-times float-right" aria-hidden="true" onClick={() => setOpen(false)}></i>
                </div>
                <div className='col-md-3 d-flex flex-column justify-content-center align-items-center'>
                    <JennaAvatar avatar={_avatar} />
                    <h5>J.E.N.N.A</h5>
                </div>
                <div className='col-md-9' style={{ maxHeight: '500px', overflowY: 'auto' }}>
                    <Fragment fragment={fragment} show={open} onClose={handleOnClose} onNavigate={setFragment} changeAvatar={handleAvatarChange} />
                </div>

            </div>

        </div>
    </Modal>
}

function IntroFragment({ show, onClose = NOOP, onNavigate = NOOP, changeAvatar = NOOP })
{
    const [welcomMessage, setWelcomeMessage] = useState('');

    useEffect(() =>
    {
        if (show)
            setWelcomeMessage(getWelcomeMessage());
    }, [show, setWelcomeMessage]);

    return <div className='row'>
        <div className='col'>
            <h5  >{welcomMessage}</h5>
            <p>I am Jenna, an A.I. virtual assistant program developed by Jerry to help you navigate through this website. I am {new Date().getFullYear() - 2019} years old
                and live in JS Cloud</p>
            <h6>Wondering what all I can do? I can help you to</h6>
            <ul>
                <li>Get to know about Mr. Jerry!</li>
                <li>Create an Estimate for your app.</li>
                <li>Navigate easily through this website.</li>
                <li>Resolve some queries about me or Mr. Jerry</li>
                <li>Apply for the contest.</li>
            </ul>
            <div className='col text-center'>
                <button className='btn btn-default mx-3' onClick={() => onNavigate('fragment-selection') || changeAvatar(AVATAR.QUESTION)}>Let's start ! shall we?</button>
                <button className='btn btn-text' onClick={onClose}>I don't need assistance</button>
            </div>
        </div>
    </div>
}

function SelectionFragment({ show, onClose = NOOP, onNavigate = NOOP, changeAvatar = NOOP })
{
    const mainRef = useRef(null);

    useEffect(() =>
    {
        mainRef.current.focus();
    }, []);



    return <div className='row'>
        <div className='col'>
            <h5 ref={mainRef} tabIndex={-1}>Before we begin...</h5>
            <p>I am designed to help you navigate through this website based on your intent. Answer some questions given below to help me understand what you are looking for.</p>
            <p>If I have to choose a category for you, what would that be?</p>
            <div className='col text-center'>
                <button className='btn btn-outline m-2' onClick={() => onNavigate('fragment-recruiter') || changeAvatar(AVATAR.BUILDING)}>I am a recruiter</button>
                <button className='btn btn-outline m-2' onClick={() => onNavigate('fragment-client') || changeAvatar(AVATAR.BUILDING)}>I am a client</button>
                <Link to='section-community' className='btn btn-outline m-2' onClick={onClose}>Apply for contest</Link>
                <button className='btn btn-text m-2' onClick={onClose}>Nevermind, I'll browse myself</button>
            </div>
            <i>Note: This is a dynamic website and contains over 50 sections which run as individual components. Every user is presented with a different layout customized to their intent.</i>
        </div>
    </div>
}


/** RECRUITER SECTION */
const RecruiterInfo = {
    context: 'about',
    title: 'About Jerin',
    content: [

        //1
        'Well, Jerin is a software developer from London, United Kingdom. He is currently working as a software developer at Timewatch Plc, United Kingdom.' +
        'He is good at developing Web and android applications and he loves open-source. ' +
        'He is an expert at Full stack and Android applications, and has started his Machine Learning journey recently. ' +
        'He is a real enthusiast, and had helped many early stage startups make their dream a reality. ' +
        'Some of his recent projects include JSCloud, a self hosted cloud network, in which I and a lot of his other personal applications run. ' +
        'Apart from all that, He is my creator and I live in JS Cloud hosted in his personal network along with all other of his creations.',

        //2
        'He is a software developer from London, United Kingdom. He is currently working as a software developer at Timewatch Plc, United Kingdom. He develops Web and android applications and loves open-source. ' +
        'He is a freelancer and an expert at Full stack and Android applications. He has started his Machine Learning journey recently. ' +
        'He is a real technology enthusiast, and had helped many early stage startups making their dream a reality. ' +
        'Some of his recent projects include JSCloud, a self hosted cloud network, in which I and a lot of his other personal applications run. ' +
        'Apart from all that, He is my creator and I live in JS Cloud hosted in his personal network along with all other of his creations.',

        //3
        'Jerin is a software developer from London, United Kingdom.  He is currently working as a software developer at Timewatch Plc, United Kingdom. He has his expertise in Web and android applications. He loves open-source and has authored several open-source projects. ' +
        'He is working as freelancer specializing in Full stack and Android applications. He began his Machine Learning journey recently. ' +
        'He is a real technology enthusiast, and had helped many early stage startups build their dream application. ' +
        'His most recent project is JSCloud, an open-source self hosted cloud network, in which I and a lot of his other personal applications run. '
    ]
}


function RecruiterFragment({ show, onClose = NOOP, onNavigate = NOOP, changeAvatar = NOOP })
{
    const [loading, setLoading] = useState(true);
    const mainRef = useRef(null);
    const TIMEOUT = 5000;
    const [infoIndex] = useState(getIndex());


    useEffect(() =>
    {
        mainRef.current.focus();

    }, [])

    useEffect(() =>
    {
        const timeout = setTimeout(() =>
        {
            setLoading(false);
            changeAvatar(AVATAR.SUCCESS)
        }, TIMEOUT);
        return () => clearTimeout(timeout);
    }, [])


    function getIndex()
    {
        return Math.floor((Math.random() * RecruiterInfo.content.length - 1) + 1);
    }

    return <div className='row'>
        <div className='col'>
            <h5 ref={mainRef} tabIndex={-1}>{RecruiterInfo.title}</h5>
            <p>{RecruiterInfo.content[infoIndex]}</p>

            {loading && <div className='col text-center'>
                <img src='/images/giphy.gif' alt='loading' height={200} width={200} />
                <h6>Please wait while I prepare a customized website for you!</h6>
            </div>}
            {!loading && <div className='my-4'>
                <RecruiterBottomButtons onNavigate={onNavigate} onClose={onClose} />
            </div>
            }

        </div>
    </div >

}

function EducationFragment({ show, onClose = NOOP, onNavigate = NOOP })
{
    const mainRef = useRef(null);
    useEffect(() =>
    {
        mainRef.current.focus();
    }, [])
    return <div className='row'>
        <div className='col'>
            <h5 ref={mainRef} tabIndex={-1}>Jerin's Educational Qualification</h5>
            <p>He has finished his M.Sc. in Advanced Computer Science from the University of Sussex, United Kingdom and has already completed his bachelors in engineering from Lovely Professional University. Please find the attached timeline of his academics for more information.
            </p>
            <Academics isJenna />
            <RecruiterBottomButtons onNavigate={onNavigate} onClose={onClose} />
        </div>
    </div>
}

function WorkFragment({ show, onClose = NOOP, onNavigate = NOOP })
{
    const mainRef = useRef(null);
    useEffect(() =>
    {
        mainRef.current.focus();
    }, [])
    return <div className='row'>
        <div className='col'>
            <h5 ref={mainRef} tabIndex={-1}>Jerin's Work Experience</h5>
            <p> He is currently working as a software developer at Timewatch Plc, United Kingdom. Although he has been involved in many complex projects for past couple of years, his professional career started when he began working as a freelancer.
                He is a successful freelancer
                having 20+ completed projects with 100% client satisfaction. He is an experienced developer having expertise in building complex Web and Android applications.
                During his bachelors he also co-founded a startup called The Fabs, which focused on providing employement opportunities for those in need.
                He has also been part of various clubs and founded several organizations focusing on simplifying application development.
                A detailed timeline of his work has been given below.
            </p>
            <Experiences isJenna />
            <RecruiterBottomButtons onNavigate={onNavigate} onClose={onClose} />
        </div>
    </div>
}

const certfications = [{
    title: 'AWS Cloud Practitioner',
    from: 'AWS',
    icon: '/images/aws.png'
}, {
    title: 'AWS EC2 Training',
    from: 'AWS',
    icon: '/images/aws.png'
}, {
    title: 'AWS DeepRacer: Reinforcement Learning',
    from: 'AWS',
    icon: '/images/aws.png'
}, {
    title: 'Android Enterprise Associate',
    from: 'Google',
    icon: '/images/google.png'
}, {
    title: 'Android Mobile Certification',
    from: 'Google',
    icon: '/images/google.png'
}]

function CertificationsFragment({ show, onClose = NOOP, onNavigate = NOOP })
{

    const mainRef = useRef(null);

    useEffect(() =>
    {
        mainRef.current.focus();
    }, [])

    return <div className='row'>
        <div className='col'>
            <h5 ref={mainRef} tabIndex={-1}>Jerin's Certifications</h5>
            <p>Jerin has been awarded several certifications including AWS Cloud Practitioner, Android Enterprise Associate, AWS DeepRacer: Reinforcement Learning, to name a few.
                Some of his certifications are listed below.</p>


            <div className='row my-4 d-flex justify-content-center'>
                {
                    certfications.map(cert => <div className='rounded bg-white shadow-dark p-3 m-1 col-md-3 d-flex flex-column justify-content-center text-center align-items-center'>
                        <img className='cert-icon' src={cert.icon} alt={cert.title} />
                        <h5>{cert.title}</h5>
                        <p>{cert.from}</p>
                    </div>)
                }

            </div>


            <RecruiterBottomButtons onNavigate={onNavigate} onClose={onClose} />
        </div>
    </div>
}

function ProjectFragment({ show, onClose = NOOP, onNavigate = NOOP })
{
    const mainRef = useRef(null);

    useEffect(() =>
    {
        mainRef.current.focus();
    }, [])

    return <div className='row'>
        <div className='col'>
            <h5 ref={mainRef} tabIndex={-1}>Jerin's Projects</h5>
            <p>His personal projects means a great deal to him. Most of his personal projects run on his home network, supported by JS Cloud.
                However, his primary goal has always been to simplify cloud and app development. Below listed are few of his projects.</p>
            <Works isJenna dataVisibleCount={3} onCloseJenna={onClose} />
            <RecruiterBottomButtons onNavigate={onNavigate} onClose={onClose} />
        </div>
    </div>
}

function RecruiterBottomButtons({ onNavigate = () => { }, onClose = () => { } })
{
    return <div className='my-4'>
        <h6>Want to know more? Choose from these section to get more details.</h6>
        <button className='btn btn-outline m-2' onClick={() => onNavigate('fragment-recruiter-education')}>Educational Qualification</button>
        <button className='btn btn-outline m-2' onClick={() => onNavigate('fragment-recruiter-work')}>Work Experience</button>
        <button className='btn btn-outline m-2' onClick={() => onNavigate('fragment-recruiter-certifications')}>Certifications</button>
        <button className='btn btn-outline m-2' onClick={() => onNavigate('fragment-recruiter-projects')}>Projects</button>
        <button className='btn btn-text m-2' onClick={onClose}>I'll browse myself</button>
    </div>
}



/*** CLient Section */


function ClientFragment({ show, onClose = NOOP, onNavigate = NOOP, changeAvatar = NOOP })
{
    const [loading, setLoading] = useState(true);
    const mainRef = useRef(null);
    const TIMEOUT = 5000;

    useEffect(() =>
    {
        mainRef.current.focus();
    }, [])

    useEffect(() =>
    {
        const timeout = setTimeout(() =>
        {
            setLoading(false)
            changeAvatar(AVATAR.SUCCESS)
        }, TIMEOUT);
        return () => clearTimeout(timeout);
    }, [])

    return <div className='row'>
        <div className='col'>
            <h5 ref={mainRef} tabIndex={-1}>Hold on!</h5>
            <p>It seems like you have a dream. How about we work together to make that a reality. Let me tell you a secret, Facebook, Google, Snapchat, all were once a simple dream.</p>

            {loading && <div className='col text-center'>
                <img src='/images/giphy.gif' alt='loading' height={200} width={200} />
                <h6>Please wait while I prepare a customized website for you!</h6>
            </div>}
            {!loading && <div className='col my-4'>
                <ClientBottomButtons onNavigate={onNavigate} onClose={onClose} />
            </div>
            }

        </div>
    </div >

}

function AndroidFragment({ show, onClose = NOOP, onNavigate = NOOP })
{
    const mainRef = useRef(null);

    useEffect(() =>
    {
        mainRef.current.focus();
    }, [])
    return <div className='row'>
        <div className='col'>
            <h5 ref={mainRef} tabIndex={-1}>Android Cost Estimator</h5>
            <p>So you wanna build an android applicatoin. That's great! We have some exciting offers for you. But First, lets have an estimate of what features you would like.</p>
            <EstimateBox estimateBoxItem={estimateItems[ANDROID]} isJenna />
            <ClientBottomButtons onNavigate={onNavigate} onClose={onClose} />
        </div>
    </div>
}

function WebFragment({ show, onClose = NOOP, onNavigate = NOOP })
{
    const mainRef = useRef(null);
    useEffect(() =>
    {
        mainRef.current.focus();
    }, [])

    return <div className='row'>
        <div className='col'>
            <h5 ref={mainRef} tabIndex={-1}>Web Cost Estimator</h5>
            <p>So you wanna build a web application. That's great! We have some exciting offers for you. But First, lets have an estimate of what features you would like.</p>
            <EstimateBox estimateBoxItem={estimateItems[WEB]} isJenna />
            <ClientBottomButtons onNavigate={onNavigate} onClose={onClose} />
        </div>
    </div>
}

function BackendFragment({ show, onClose = NOOP, onNavigate = NOOP })
{
    const mainRef = useRef(null);
    useEffect(() =>
    {
        mainRef.current.focus();
    }, [])

    return <div className='row'>
        <div className='col'>
            <h5 ref={mainRef} tabIndex={-1}>Backend Cost Estimator</h5>
            <p>So you wanna build a backend application. That's great! We have some exciting offers for you. But First, lets have an estimate of what features you would like.</p>
            <EstimateBox estimateBoxItem={estimateItems[BACKEND]} isJenna />
            <ClientBottomButtons onNavigate={onNavigate} onClose={onClose} />
        </div>
    </div>
}

function UpgradeFragment({ show, onClose = NOOP, onNavigate = NOOP })
{
    const mainRef = useRef(null);
    useEffect(() =>
    {
        mainRef.current.focus();
    }, [])

    return <div className='row'>
        <div className='col'>
            <h5 ref={mainRef} tabIndex={-1}>Upgrade/Fix your app</h5>
            <p>So you wanna upgrade/fix your application. That's great! We have some exciting offers for you. Unfortunately, we don't have an estimator for this category.
                How about getting a custom quote?</p>
            <Link to='section-contact' className='btn btn-default' onClick={onClose}>Get a detailed quote</Link>
            <ClientBottomButtons onNavigate={onNavigate} onClose={onClose} />
        </div>
    </div>
}

function ClientBottomButtons({ onNavigate = () => { }, onClose = () => { } })
{
    return <div className='my-4'>
        <h6>Choose from these section to get more details.</h6>
        <button className='btn btn-outline m-2' onClick={() => onNavigate('fragment-client-android')}>Android Application</button>
        <button className='btn btn-outline m-2' onClick={() => onNavigate('fragment-client-web')}>Web Applicatoin</button>
        <button className='btn btn-outline m-2' onClick={() => onNavigate('fragment-client-backend')}>Backend Application</button>
        <button className='btn btn-outline m-2' onClick={() => onNavigate('fragment-client-upgrade')}>Upgrade/Fix</button>
        <button className='btn btn-text m-2' onClick={onClose}>I'll browse myself</button>
    </div>
}
