import React, { useEffect, useState } from "react";

export const AVATAR = {
    BUILDING: '/jenna/SVG/building.svg',
    SUCCESS: '/jenna/SVG/success.svg',
    HELLO: '/jenna/SVG/hello.svg',
    QUESTION: '/jenna/SVG/question.svg',
    SEARCHING: '/jenna/SVG/searching.svg',
    WONDER: '/jenna/SVG/wonder.svg',

}
export default function JennaAvatar({ avatar = AVATAR.HELLO, ...props })
{
    const [_avatar, set_Avatar] = useState(avatar);
    const [cssClass, setcssClass] = useState(' ');

    function fadeIn()
    {
        setcssClass('fadeIn');
    }

    function fadeOut()
    {
        setcssClass('fadeOut');
    }

    useEffect(() =>
    {

        fadeOut();
        setTimeout(() =>
        {
            set_Avatar(avatar);
            fadeIn();
        }, 1000);
    }, [avatar])

    return <img className={cssClass} src={_avatar} style={{ maxHeight: '200px' }} alt='Jenna' />
}

