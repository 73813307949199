import React from "react";
import Pagetitle from "../elements/Pagetitle";
import Timeline from "../elements/Timeline";

const educationData = [
    {
        id: 1,
        title: "MSc Advanced Computer Science",
        years: "2022 - 2023",
        content:
            "University of Sussex",
    },
    {
        id: 2,
        title: "Bachelor of Technology",
        years: "2016-2020",
        content:
            "Lovely Professional University",
    },
];


export default function Academics({ isJenna = false })
{
    if (isJenna)
        return (
            <div className="col-12 my-2">
                <div className="timeline edu bg-white rounded shadow-dark padding-30 overflow-hidden">
                    {educationData.map((education) => (
                        <Timeline key={education.id} education={education} />
                    ))}
                    <span className="line"></span>
                </div>
            </div>
        )


    return (
        <section id="academics" data-analytics-view="section-academics">
            <div className="container">
                <Pagetitle title="Academics" />
                <div className="row" >
                    <div className="col-12 my-2">
                        <div className="timeline edu bg-white rounded shadow-dark padding-30 overflow-hidden">
                            {educationData.map((education) => (
                                <Timeline key={education.id} education={education} />
                            ))}
                            <span className="line"></span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

